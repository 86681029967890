import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const showToast = {
  methods: {
    showToast(response, element, title = null, customText = null, timeout = 2000) {
      let icon
      let text
      let variant
      switch (response.status) {
        case 'success':
        case 200:
        case 201:
          icon = 'BellIcon'
          text = customText || `👋 ${element} - ${response.statusText}`
          variant = 'success'
          break
        case 'error':
          icon = 'AlertOctagonIcon'
          text = customText || `👋 ${element} - ${response.statusText}`
          variant = 'danger'
          break
        default:
          icon = 'AlertCircleIcon'
          text = customText || `${element} - ${response.statusText}`
          variant = 'warning'
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title || 'Notification',
          icon,
          text,
          variant,
        },
      },
      {
        timeout,
      })
    },
  },
}

export const _ = null
